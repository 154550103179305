import React from 'react'
import Layout from '../components/Layout'
import Form from '../components/Form/unsubscribe'
import View from '../components/View'
import { globalHistory } from '@reach/router'
import { isLoggedIn } from '../utils/auth'
import { parseQuery } from '../utils/query'
import { isBrowser } from '../utils/misc'
import { unsubscribeRecipient } from '../utils/email'

class Unsubscribe extends React.Component {
  state = {
    email: ``,
    logId: ``,
    errorMessage: ``,
    successMessage: ``,
  }

  componentDidMount() {
    var query = this.state.query
    if (globalHistory.location.search) {
      query = parseQuery(globalHistory.location.search)
      if (query.email) {
        this.setState({
          email: query.email,
        })
      }
      if (query.logid) {
        this.setState({
          logId: query.logid,
        })
      }
    }
  }
  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    unsubscribeRecipient(
      this.state.email,
      function (err, data) {
        if (err) {
          console.log(err)
        }
        if (data.unsubscribed) {
          this.setState({
            successMessage:
              'Your email has been unsubscribe from futher communications.',
          })
        }
      }.bind(this)
    )
  }

  redirect() {
    if (isBrowser) {
      window.location.href = '/'
    }
  }

  render() {
    if (isBrowser) {
      if (isLoggedIn()) {
        this.redirect()
      }
      return (
        <Layout>
          <View title="Unsubscribe from Emails">
            <Form
              handleUpdate={(e) => this.handleUpdate(e)}
              handleSubmit={(e) => this.handleSubmit(e)}
              email={this.state.email}
            />
            <label>{this.state.errorMessage}</label>
            <label>{this.state.successMessage}</label>
          </View>
        </Layout>
      )
    } else {
      return null
    }
  }
}

export default Unsubscribe
