import React from 'react'
import * as styles from './form.module.css'

const UnsubscribeForm = (({ handleSubmit, handleUpdate, history, email }) => (
  <form
    className={styles.form}
    method="post"
    onSubmit={event => {
      handleSubmit(event)
    }}
  >
    <p className={styles[`form__instructions`]}>
      Please submit your email if you wish to be unsubscribed from further e-mail communications.
    </p>
    <label className={styles[`form__label`]}>
      Email
      <input
        className={styles[`form__input`]}
        type="email"
        name="email"
        onChange={handleUpdate}
        value={email}
      />
    </label>
    <input className={styles[`form__button`]} type="submit" value="Unsubscribe" />
  </form>
))

export default UnsubscribeForm
